import React from 'react'
import {Badge, Box,Typography} from '@mui/material';
import TemplatedMessageInitializer from './TemplatedMessageInitializer';
import {useAppStore } from '../Main/AppStore';


function NotificationBar() {
  const {notificationsCount} = useAppStore();
  // console.log("Total Notifications Count",notificationsCount)
  return (
   <React.Fragment>
     <Box
      sx={{
        display:'inline-flex',
        flexDirection:'row',
        flexWrap:'wrap',
        justifyContent:'left',
        alignItems:'center',
        width:'100%'
      }}
    >
      <Typography sx={{fontFamily:'DM Sans Light',fontSize:'18px',fontWeight:600}}>Conversations</Typography>
        <Box sx={{ml:'auto',display:'flex',flexDirection:'row',alignItems:'center',gap:4}}>
            <TemplatedMessageInitializer/>
            {notificationsCount>0 &&
              <Badge color="secondary" sx={{mr:2}} badgeContent={notificationsCount} max={99} />
            }
        </Box>
    </Box>
   </React.Fragment>
  )
}

export default NotificationBar